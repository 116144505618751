import BaseInstance from "../base.instance";
import { NEWS_LIST, NEWS_DETAIL, NEWS_SAVE, NEWS_DELETE } from "../constants";

const NewsService = {
  getList(data) {
    return BaseInstance.post(NEWS_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(NEWS_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(NEWS_SAVE, data);
  },
  delete(id) {
    return BaseInstance.deleteSlug(NEWS_DELETE, id);
  }
};

export default NewsService;
