<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Berita</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="px-0">
              <v-card-actions>
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="handleCreate"
                  >Buat Berita<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="px-1">
              <v-select
                v-model="status"
                :items="statusList"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="px-1">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="news"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.updated_at`]="{ item }">
              <span>{{ item.updated_at.date | date }}</span>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span>{{ item.description | str_limit(200) }}</span>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-img
                :src="item.image"
                :alt="item.title"
                v-if="$vuetify.breakpoint.smAndUp"
                contain
                width="400"
                height="150"
                class="my-2"
              />
              <a
                v-else
                :href="item.image"
                target="_blank"
                class="subtitle-2 hover-primary"
              >
                Download
              </a>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Edit</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="800">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Berita
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Judul</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.title"
                outlined
                dense
                :rules="[v => !!v || 'Judul harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Deskripsi</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.description"
                outlined
                dense
                :rules="[v => !!v || 'Deskripsi harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Konten</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <vue-editor v-model="form.content"></vue-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Gambar</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-file-input
                v-model="form.image"
                counter
                :rules="
                  form.imageTemp
                    ? undefined
                    : [v => !!v || 'Gambar harus diisi']
                "
                placeholder="Select your files"
                prepend-icon="image"
                :show-size="1000"
              />
              <p class="subtitle-2 mt-2" v-if="form.imageTemp">
                Current Image:
                <span class="hover-accent" @click="form.imageTemp">{{
                  form.imageTemp
                }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Aktif</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-switch v-model="form.active"></v-switch>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PublicService from "@/services/resources/public.service";
import NewsService from "@/services/resources/news.service";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      loadingUnit: false,
      formLoading: false,
      statusList: ["ACTIVE", "INACTIVE"],
      units: [],
      search: null,
      status: "ACTIVE",
      headers: [
        {
          text: "Tanggal",
          value: "updated_at",
          sortable: false,
          align: "center"
        },
        {
          text: "Judul",
          value: "title",
          sortable: false,
          align: "center"
        },
        {
          text: "Dekripsi",
          value: "description",
          sortable: false,
          align: "center"
        },
        {
          text: "Gambar",
          value: "image",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      news: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["id"],
        sortDesc: [false]
      },
      dayDiffTry: 0,
      dayDiff: 0,
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        id: null,
        author: null,
        title: null,
        description: null,
        content: null,
        image: null,
        imageTemp: null,
        active: true,
        status: "ACTIVE"
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListNews);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListNews);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    paginationProperty() {
      return [this.search, this.status].join();
    }
  },
  methods: {
    handleCreate() {
      this.visible = true;
      (this.form.content = null), (this.form.imageTemp = null);
      this.form.status = "ACTIVE";
    },
    handleEdit(item) {
      this.visible = true;
      this.getDetailNews(item.id);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteNews(item.id);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("author", this.currentUser.personal.nama);
        formData.append("title", this.form.title);
        formData.append("description", this.form.description);
        formData.append("content", this.form.content);
        formData.append("image", this.form.image);
        formData.append("status", this.form.active ? "ACTIVE" : "INACTIVE");
        this.saveNews(formData);
      }
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await PublicService.getUnitKerjaList2(0)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              data.push({
                unit_kerja_2_id: null,
                singkatan: "- Semua Unit -"
              });
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListNews() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await NewsService.getList({
          filter: {
            search: this.search,
            status: this.status
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let news = list;
              news.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.news = news;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveNews(data) {
      try {
        this.formLoading = true;
        await NewsService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (this.dayDiffTry >= this.dayDiff) {
              if (status) {
                this.getListNews();
                this.visible = false;
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "success"
                });
              } else {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "error"
                });
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
            this.dayDiffTry = this.dayDiffTry + 1;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailNews(id) {
      try {
        this.loading = true;
        await NewsService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form.id = data.id;
              this.form.author = data.author;
              this.form.title = data.title;
              this.form.description = data.description;
              this.form.content = data.content;
              this.form.imageTemp = data.image;
              this.form.active = data.status == "ACTIVE";
              this.form.status = data.status;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteNews(payload) {
      try {
        this.formLoading = true;
        await NewsService.delete(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListNews();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
